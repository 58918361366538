@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Nunito+Sans:wght@400;600;700&family=Roboto:wght@400;700&display=swap);
*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


#root {
  min-height: 100%;
  display: flex;
  flex-flow: column nowrap;
}

